<template>
    <section>
        <div class="row mx-0">
            <menu-categorias v-show="viewMenuCategorias" />
            <div class="col home-page pt-3 pl-4 overflow-auto scroll-none" style="height:calc(100vh - 115px);">
                <div class="banner-invitado d-middle mb-4 row mx-2">
                    <div class="col px-0 pl-3 pt-1 cr-pointer" @click="irRegistrarse">
                        Tenemos <span class="f-600">{{ totalProductos ? totalProductos : 'muchos' }}</span>  productos para ti <br /> Regístrate para tener una mejor experiencia
                    </div>
                    <div class="col-12 col-md-3">
                        <div class="btn-registrarse mx-auto my-2 d-middle-center cr-pointer" @click="irRegistrarse">
                            Registrarse
                        </div>
                    </div>
                </div>
                <template v-if="promociones.length > 0">
                    <div class="row mx-0 title-category">
                        <p class="col px-0 f-28 text-general f-600">
                            Ofertas especiales
                        </p>
                        <div class="col-12" />
                        <div class="like-bar mb-2" />
                    </div>
                    <div class="row mx-0 pl-xl-3 pl-lg-3 pl-md-2 pl-sm-2 pl-2">
                        <div class="col-12 px-0 scroll-none overflow-auto">
                            <div class="mt-2" style="display:inline-flex;">
                                <img v-for="(data,index) in promociones" :key="index" :src="data.imagen ? data.imagen :'/img/no-imagenes/promociones.png'" class="img-categories br-12 mr-4 cr-pointer" width="440" height="160" @click="verPromocion(data.id)" />
                            </div>
                        </div>
                    </div>
                </template>
                <div class="row-mx-0 py-2 spacer" />

                <slider-categorias @countProductos="countProductos" />

                <div v-if="categoriaActiva != 0 && categoriaActiva != null" class="banner-invitado d-middle mb-4">
                    <div class="col px-0 pl-4 f-22 lh-32">
                        Tenemos <span class="f-600">{{ totalProductos ? totalProductos : 'muchos' }}</span>  productos para ti <br /> Regístrate para tener una mejor experiencia
                    </div>
                    <div class="col-3">
                        <div class="btn-registrarse d-middle-center cr-pointer" @click="irRegistrarse">
                            Registrarte
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { mapGetters } from 'vuex'
import Service from '~/services/invitado/invitado'
export default {
    components: {
        menuCategorias:()=> import('./categorias/components/menuCategorias.vue'),
        sliderCategorias:()=> import('./categorias/components/sliderCategorias.vue'),
    },
    data(){
        return{
            cargando: false,
            categorias: [],
            categoriasProductos: [],
            promociones: [],
            categoriaActiva: 0,
            totalProductos: 0,
        }
    },
    computed: {
        ...mapGetters({
            tienda: 'invitado/tienda',
            viewMenuCategorias:'home/viewMenuCategorias'
        }),
    },
    mounted(){
        if(this.tienda){
            this.getPromociones();
        } 
    },
    methods: {
        
        async getPromociones(){
            try {
                
                this.cargando = true;
                if(!this.tienda.id_tienda){
                    await this.$store.dispatch('invitado/getTiendaDefault')
                }
                const params = {
                    idTienda: this.tienda.id_tienda,
                };
                const { data } = await Service.getBannersPromocion(params);
                this.promociones = data.promociones;
            } catch(e){
                this.errorCatch(e)
            } finally{
                this.cargando = false;
            }
        },
        countProductos(cantidad){
            this.totalProductos = cantidad
        },
        verMasProductos(idCategoria){
            this.categoriaActiva = idCategoria;
            this.getProductosCategoria();
        },
        verPromocion(idPromocion){
            this.$router.push({name: 'invitado.promocion.ver', params:{idTienda: this.tienda.id_tienda, idPromocion: idPromocion }})
        },
        irRegistrarse(){
            this.$router.push({name:'registro'});
        },
        
    }
}
</script>
<style lang="scss" scoped>

.panel-categories{
  height: calc(100vh - 80px);
  width: 245px;
  background-color: #FFFFFF;
  box-shadow: 0px 2px 4px #00000014;
}

.like-bar{
    width: 44px;
    height: 5px;
    background-color: var(--color-general);
}

.img-categories{
  box-shadow: 0px 3px 6px #00000029;
}

.tabs-mobile{
  display: none !important;
}

.banner-invitado{
    //height: 70px;
    color: #FFFFFF;
    border-radius: 12px;
    box-shadow: 0px 3px 6px #00000029;
    background: var(--color-general);
    .btn-registrarse{
        background-color: #FFFFFF;
        color: var(--color-general);
        font-weight: 500;
        max-width: 182px;
        height: 44px;
        border-radius: 12px;
    }
}

.card-producto{
    width: 165px;
    min-height: 228px;
    box-shadow: 0px 3px 6px #0000000D;

    &.full-width-card{
        width: 100% !important;
    }
}

@media (min-width: 300px) and (max-width: 815px) {


  .title-category{
    display: none !important;
  }  

  .img-categories{
    width: 213px;
    height: 86px;
  }
  
  .tabs-mobile{
    display: block !important;
  }

  .titles{
    font-size: 17px !important;
    font-weight: 500 !important;
  }

  .spacer{
    display: none !important;
  }

  .text-arrow{
    display: none !important;
  }
  .like-bar{
    display: none !important;
  }
}

.card-producto{
    width: 165px;
    min-height: 228px;
    box-shadow: 0px 3px 6px #0000000D;

    &.full-width-card{
        width: 100% !important;
    }
}
</style>
